import React from "react"
import { Page } from "../components/elements"
import { Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { PageCenter } from "../components/elements/wrappers"
import { Helmet } from "react-helmet"
import { DefaultHeader, CoreCard } from "../components/elements"
import { graphql, Link, useStaticQuery } from "gatsby"

export default function HomePage() {
	const {
		q1: {
			frontmatter: { components: c },
		},
	} = useStaticQuery(graphql`
		{
			q1: markdownRemark(frontmatter: { id: { eq: "elem-03" } }) {
				...ElementFields
			}
		}
	`)

	return (
		<Page crumbs={false}>
			<Helmet>
				<style type="text/css">
					{`
					.main-page {
						position: relative;
					}

					.backgroundImage {
						--bg-color: rgba(46, 43, 39, 0.28);
						position: absolute !important;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}

					.backgroundImage::after {
						content: "";
						position: absolute !important;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: linear-gradient(var(--bg-color), var(--bg-color));
					}

					.memo {
						position: relative;
						width: fit-content;
						height: fit-content;
					}

					.memo::after {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 40%;
						height: 1.5px;
						content: "";
						background: var(--red1);
					}

					.resources a {
						text-decoration: none;
						transition: color 0.2s ease;
					}

					.resources a img {
						border: 2px solid white;
						box-shadow: 2px 2px 5px var(--shadow);
						transition: border 0.2s ease;
					}

					.resources a:hover img {
						border: 2px solid var(--red1);
					}

					.ca-benefits .list-group-item {
						cursor: pointer;
					}

					.list-group-item.active {
						z-index: 2;
						color: #fff;
						background-color: var(--red1);
						border-color: var(--red1);
					}

					.research-areas-title {
						margin:auto;
						background-color: #f8f9fa;
						color:#343a40;
						font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
						font-weight: 300;
						font-size:21px;
						max-width: 800px;
						line-height: 1.5;
						white-space: pre-line;
						text-align: center;
					}



					.egicrsl {
						background-color: #f8f9fa;
					  }
					  
					  .egicrsl-title {
						margin:auto;
						background-color: #f8f9fa;
						color:#343a40;
						font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
						font-weight: 300;
						font-size:21px;
						max-width: 800px;
						line-height: 1.5;
						white-space: pre-line;
						text-align: center;
					  }
					  
					  .egicrsl-body {
						background-color: #f8f9fa;
					  
						position: relative;
					  
						left: calc(50% - 400px);
						min-height:600px;
						max-width:800px;
					  }
					  
					  .aapg{
						position: relative;
						margin: auto;
						align-items: center;
						display: flex-box;

					  }

					  .egicrsl-tile {
						background-color: #f8f9fa;
					  
						position: absolute;
						left: 0px;
						top: 0px;
					  
						margin: 0 auto;
					  
						min-width: 800px;
						max-width: 800px;
						min-height: 600px;
						max-height: 600px;
					  
						transition: all 0.5s ease-in-out;
					  }
					  
					  .horz-center-center {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
					  }
					  
					  .arrow{
						border: solid black;
					  
						border-width: 0 3px 3px 0;
						display: inline-block;
						padding: 12px;
					  }
					  .arrow-prev {
						transform: translateX(15px) rotate(135deg);
					  }
					  .arrow-next {
						transform: translateX(-15px) rotate(-45deg);
					  }
					  
					  .egicrsl-frame {
						background-color: #f8f9fa;
					  
						min-width: 800px;
						max-width: 800px;
						min-height: 500px;
						max-height: 500px;
					  }
					  
					  .egicrsl-image {
						background-color:violet;
					  
						display: block;
						margin: auto;
					  }
					  
					  .egicrsl-caption {
						background-color: #f8f9fa;
						
						padding: 5px 5px 5px 5px;
						min-height: 100px;
					  }

					  .egicrsl-caption-title {
						margin: 0;
						font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
						line-height: 1.5;
						font-size: 2rem;
						color: #212529;
						text-align: center;
						font-weight: 500;
					  }

					  .egicrsl-caption-text {
						margin: 0;
    					font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.5;
						color: #212529;
						text-align: center;
					  }
					  
					  
					  /*** Hidden Targets ***/
					  
					  [id^= "tile"] {
						display: none;
					  }
					  
					  *:target {
						background-color: red;
					  }
					  
					  /* Hide all tiles and push all of them to the bottom */
					  .tile-1, .tile-2, .tile-3, .tile-4, .tile-5, .tile-6,
					  .tile-7, .tile-8, .tile-9, .tile-10, .tile-11, .tile-12,
					  .tile-13, .tile-14, .tile-15, .tile-16, .tile-17, .tile-18
					  {
						z-index: 0;
						opacity: 0;
					  }
					  
					  /* Show tile-1 and bring it to the top */
					  .tile-1 {
						z-index: 1;
						opacity: 1;
					  }
					  
					  /* If there is a target, hide tile-1 and push it to the bottom */
					  *:target ~ .tile-1 {
						z-index: 0;
						opacity: 0;
					  }
					  
					  /* If there is a target, show the tile that is the target */
					  #tile-1:target ~ .tile-1, 
					  #tile-2:target ~ .tile-2, 
					  #tile-3:target ~ .tile-3,
					  #tile-4:target ~ .tile-4,
					  #tile-5:target ~ .tile-5,
					  #tile-6:target ~ .tile-6,
					  #tile-7:target ~ .tile-7,
					  #tile-8:target ~ .tile-8,
					  #tile-9:target ~ .tile-9,
					  #tile-10:target ~ .tile-10,
					  #tile-11:target ~ .tile-11,
					  #tile-12:target ~ .tile-12,
					  #tile-13:target ~ .tile-13,
					  #tile-14:target ~ .tile-14,
					  #tile-15:target ~ .tile-15,
					  #tile-16:target ~ .tile-16,
					  #tile-17:target ~ .tile-17,
					  #tile-18:target ~ .tile-18
					  {
						z-index: 1;
						opacity: 1;
					  } 
					  
					  


					`}
				</style>
			</Helmet>
			<PageCenter bg="dark" text="light" className="mw-100 p-5 main-page">
				<StaticImage
					src="../../static/media/Spring View-SLC-8.jpg"
					placeholder="none"
					alt="Background image"
					className="backgroundImage"
				/>
				<Row className="w-100 py-3" style={{ margin: "0 auto" }}>
					<Col
						style={{
							minWidth: "300px",
							maxWidth: "500px",
						}}
					>
						<h1 className="display-3 mb-5">
							Leading <br /> The Energy Transition
						</h1>
						<p
							className="lead p-3 border b"
							style={{
								fontSize: "1.05rem",
								background: "#2e2b27ce",
							}}
						>
							EGI’s mission is to create innovative science and
							technology to explore for and produce Earth’s energy
							and mineral resources sustainably.
							<br />
							<br />
							We deliver high quality research, knowledge-base,
							data science, and insights to solve subsurface
							geologic and engineering problems related to
							hydrocarbon exploration and recovery, mineral
							extraction, geothermal energy, and carbon management in global collaboration with academic,
							industry, and government organizations.
						</p>
					</Col>
					<Col
						style={{
							minWidth: "300px",
							maxWidth: "500px",
						}}
					>
						{c[0].items.map(({ item: { title, sub_items } }, i) => (
							<div
								key={i}
								className="p-3 my-3 border w-100"
								style={{
									maxWidth: "350px",
									float: "right",
									background: "rgba(46, 43, 39, 0.70)",
								}}
							>
								<h2 className="lead border-bottom pb-3 mb-3">
									{title}
								</h2>

								<ul
									className="pl-4"
									style={{
										listStyleType: "square",
									}}
								>
									{sub_items.map(({ sub_item: sub }, i) => {
										return (
											<li className=" m-2" key={i}>
												{sub?.external ? (
													<a
														target="_blank"
														href={sub?.href}
														className="text-light"
														style={{
															textDecoration:
																"none",
														}}
													>
														{sub?.title}
													</a>
												) : (
													<Link
														to={sub?.href}
														style={{
															textDecoration:
																"none",
														}}
														className="text-light"
													>
														{sub?.title}
													</Link>
												)}
											</li>
										)
									})}
								</ul>
							</div>
						))}

					</Col>
				</Row>
			</PageCenter>
			<PageCenter bg="light" text="dark">
				<DefaultHeader
					title="Core Research Areas"
					subtitle="EGI is your source for high-quality, scientific and engineering research in 
					pursuit of the answers to the pressing questions our industry faces."
				/>

				<div
					className="d-flex flex-wrap"
					style={{ justifyContent: "center" }}
				>
					<CoreCard
						title="Energy Fluids and Minerals"
						// subtitle="Oil and Gas"
						button="Learn More"
						href="/research/energy-fluids-and-materials"
					>
						<span className="description">
							EGI hydrocarbon knowledge database consists of
							reports from hundreds of member-driven projects all
							over the world.
						</span>
						<span className="description">
							Current developmental research includes fundamentals
							of fluid flow, rock physics, and fractures that are
							crosscutting EGI research areas of geothermal,
							carbon management, petroleum geoscience and
							reservoir engineering.
						</span>
					</CoreCard>
					<CoreCard
						title="Carbon Management"
						// subtitle="Carbon capture & storage"
						button="Learn More"
						href="/research/carbon-management"
					>
						<span className="description">
							EGI conducts numerous projects in carbon
							sequestration wherein large amounts of anthropogenic
							CO₂ emissions are targeted for injection into deep
							saline aquifers or other suitable geologic
							formations.
						</span>
						<span className="description">
							In partnership with departments and colleges, EGI’s
							research portfolio on low-carbon technologies
							includes work in carbon capture, carbon utilization
							and direct air capture of CO₂.
						</span>
					</CoreCard>
					<CoreCard
						title="Geothermal Research"
						// subtitle="Renewable Energy"
						button="Learn More"
						href="/research/geothermal-research"
					>
						<span className="description">
							Using cutting edge tools, our staff have deep
							expertise in applied geoscience and engineering
							required to explore, drill and develop geothermal
							resources.
						</span>
						<span className="description">
							It is now on the forefront of Enhanced Geothermal
							Systems (EGS) technology with the Utah FORGE
							project, which aims to produce power from hot dry
							rock.
						</span>
					</CoreCard>
				</div>

			</PageCenter>

			<PageCenter bg="light" text="dark">				
				
				<div className="egicrsl">
					<div className="egicrsl-body">
				
						<div className="egicrsl-tile tile-1">
							<div className="egicrsl-frame horz-center-center">
								<p align="center"><img className="egicrsl-image" src="/media/AAPG1.PNG" width="750" alt="slider1_image" /></p>
							</div>							
						</div>

					</div>
				</div>

			</PageCenter>


		</Page>
	)
}
